Copy code
<template>
  <div>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-card outlined>
        <v-card-title class="text-center">Actualiser les données des entreprises manuelles</v-card-title>
        <upload-error :error="error"/>
        <v-card-text>
          <v-file-input
            v-model="file"
            label="Choisissez un fichier"
            prepend-icon="mdi-paperclip"
            accept=".xlsx"
          ></v-file-input>
        </v-card-text>
        <v-card-actions class="justify-center">
          <!-- <v-btn color="primary" @click="ExportModel" :disabled="loading" class="btn-large">Export Modèle</v-btn> -->
          <v-btn color="primary" @click="ImportData" :disabled="loading" class="btn-large">Import Data</v-btn>
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";

@Component({mixins: [formatter]})
export default class PortfolioForm extends Vue {

  @Prop() portfolioId;

  portfolio =  null;
  error = null;
  file = null;
  loading= false;

  mounted() {
    // get or create portfolio object
    // if (this.portfolioId) {
    //   this.$api.get(`portfolio/${this.portfolioId}`).then(j => {
    //     this.portfolio = j.data;
    //   });
    // } else {
    //   this.portfolio = {
    //     name: "",
    //     devise: "",
    //   }
    // }
  }

  // async ExportModel() {
  //     try {
  //       let id = parseInt(this.portfolioId) || 0;

  //       // Make the API call
  //       const response = await this.$api.get(`portfolio/export?id=${id}`, { responseType: 'blob' });

  //       // Handle the response
  //       const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'ExportedPortfolio.xlsx');
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } catch (err) {
  //       if (err.response) {
  //         this.error = err.response.data;
  //       } else {
  //         this.error = 'An unexpected error occurred';
  //       }
  //     }
  // }

  async ImportData() {
    this.loading = true;
      try {
        let id = parseInt(this.portfolioId) || 0;

        // Prepare form data
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('id', id);

        // Make the API call
        const response = await this.$api.post(`portfolio/import?id=${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // Emit the save event
        this.$emit('save', response.data);
        // Make the API call
        const resps = await this.$api.post(`portfolio/recalculate-related-portfolio?id=${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (err) {
        if (err.response) {
          this.error = err.response.data;
        } else {
          this.error = 'An unexpected error occurred';
        }
      } finally {
        this.loading = false;
      }
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>
<style scoped>
.text-center {
  text-align: center;
}

.justify-center {
  display: flex;
  justify-content: center;
  gap: 16px; /* Adds space between buttons */
}

.btn-large {
  font-size: 18px;
  padding: 12px 24px;
}
</style>
<style>
#fileInput {
  display: none;
}
h2 {
  margin: 1em 0;
}
</style>
